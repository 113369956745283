import React from 'react';
// import { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import Car from './Car';
import App from './App';
// import App2 from './App2';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


// function Football() {
//   const shoot = (a) => {
//     alert(a);
//   }

//   return (
//     <button onClick={() => shoot("Goal!")}>Take the shot!</button>
//   );
// }
// root.render(<Football />)

// function MissedGoal() {
//   return <h1>MISSED!</h1>;
// }

// function MadeGoal() {
//   return <h1>Goal!</h1>;
// }

// function Goal(props) {
//   const isGoal = props.isGoal;
//   return (
//     <>
//     { isGoal ? <MadeGoal /> : <MissedGoal /> }
//   </>
//   );

//   // if (isGoal) {
//   //   return <MadeGoal/>;
//   // }
//   // return <MissedGoal/>;
// }

// root.render(<Goal isGoal={true} />);



///

// function Garage(props) {
//   const cars = props.cars;
//   return (
//     <>
//       <h1>Garage</h1>
//       {cars.length > 0 &&
//         <h2>
//           You have {cars.length} cars in your garage.
//         </h2>
//       }
//     </>
//   );
// }

// const cars = ['Ford', 'BMW', 'Audi'];
// const cars = ["lancer"];
// root.render(<Garage cars={cars} />);

// const myelement = (
//   <table>
//     <tr>
//       <th>Name</th>
//     </tr>
//     <tr>
//       <td>John</td>
//     </tr>
//     <tr>
//       <td>Elsa</td>
//     </tr>
//   </table>
// );

// root.render(myelement);

// function Garaz() {
//   return (
//     <>
//     <h1>Joł garaż!</h1>
//     <Car />
//     </>
//   )
// }

// root.render(<Garaz />);



// function Car(props) {
//   return <li>I am a { props.brand } number { props.id }</li>;
// }

// function Garage() {
//   const cars = [
//     {id: 1, brand: 'Ford'},
//     {id: 2, brand: 'BMW'},
//     {id: 3, brand: 'Audi'}
//   ];
//   return (
//     <>
//       <h1>Who lives in my garage?</h1>
//       <ul>
//         {cars.map((car) => <Car key={car.id} brand={car.brand} id={car.id} />)}
//       </ul>
//     </>
//   );
// }

// root.render(<Garage />)



// function MyForm() {
//   const [name, setName] = useState(""); //useState zwraca [zmienna, funkcje do zmiany zmiennej]

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     alert(`Twoje imie to: ${name}`)
//   }

//   return (
//     <form onSubmit={handleSubmit}>
//       <label>Enter your name:
//         <input
//           type="text" 
//           value={name}
//           onChange={(e) => setName(e.target.value)}
//         />
//       </label>
//       <input type="submit"/>
//     </form>
//   )
// }


// root.render(<MyForm />)




// on click?
// root.render(
//   <React.StrictMode>
//     <App2 />
//   </React.StrictMode>
// )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
